import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import { navigate } from "gatsby";
import React, { useState } from "react";
import {
  CAlert,
  CButton,
  CFormControl,
  CLink,
  CTextField,
  SubmitButton
} from "../utils/globalStyles";
import Layout from "../components/layout";
import { signIn, user } from "../utils/auth";
import { NavigatedToState } from "../types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import { TransitionProps } from '@mui/material/transitions';
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = NavigatedToState;
const SignIn: React.FC<Props> = ({ location }) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      <Container maxWidth="sm">
        <>
          <Typography variant="h4">Sign In</Typography>
          <form
            onSubmit={async e => {
              e.preventDefault();
              if (typeof window === "undefined") return;
              try {
                await signIn.withPassword(email, password);
                location?.state?.navigatedTo
                  ? navigate(-1)
                  : navigate("/profile");
              } catch (e) {
                setError(e.message);
                console.log(e);
              }
            }}
          >
            <CFormControl fullWidth>
              <CTextField
                label="Email"
                variant="filled"
                value={email}
                required
                type="email"
                onChange={e => setEmail(e.target.value)}
              />
            </CFormControl>

            <CFormControl fullWidth>
              <CTextField
                label="Password"
                required
                variant="filled"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </CFormControl>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>
                Don't have an account yet?{" "}
                <CLink to="/sign-up" navigatedUntrue={true}>
                  Sign up
                </CLink>
              </Typography>

              <Typography onClick={() => setOpen(true)}>
                <CLink to="#" navigatedUntrue={true}>
                  Forgot password
                </CLink>
              </Typography>

              <Dialog open={open} onClose={() => setOpen(false)} keepMounted TransitionComponent={Transition}>
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To reset this password, please enter your email address below and you will receive a reset link per email.
                  </DialogContentText>
                  <CTextField
                    autoFocus
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    required
                    variant="filled"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ margin: "30px 0 20px 0" }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <CButton color="primary" variant="contained" autoFocus onClick={() => {
                    setOpen(false);
                    user.resetPassword(email);
                  }}>Send</CButton>
                </DialogActions>
              </Dialog>
            </div>

            <SubmitButton color="primary" variant="contained" type="submit">
              Sign In
            </SubmitButton>
            {error ? (
              <CAlert severity="error" variant="outlined">
                {error}
              </CAlert>
            ) : null}
          </form>
        </>
      </Container>
    </Layout>
  );
};

export default SignIn;
